import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LeadInterface} from "app/models/lead";
import moment from "moment";
import {getValueFromQueryParams} from "_metronic";
import {unscheduledActions} from "./actions";
import {UnscheduledState} from "./types";

// >>>>>>> Scheduling Initial State <<<<<<<<<
const initialState: UnscheduledState = {
  leadList: {
    isFetching: false,
    data: [],
  },
  loanServicingJobList: {
    isFetching: false,
    data: [],
    refreshState: true,
    selectedJob: null,
  },
  pickupLocation: {
    isFetching: false,
    data: null,
  },
  lsJobAssignAgent: {
    isFetching: false,
    success: false,
    data: {
      city: "",
      cityId: 0,
      agentId: 0,
      crType: "GOLD_LOAN_CLOSURE_PACKET_RELEASE",
      jobType: "RM_LOAN_RELEASE",
      loanClassification: "NEW",
      name: "",
      lmLockerId: 0,
      startTime: moment()
        .startOf("day")
        .valueOf(),
      reportingTime: moment()
        .startOf("day")
        .valueOf(),
      endTime: moment()
        .startOf("day")
        .valueOf(),
      addressLatitude: "",
      addressLongitude: "",
      landmarkLatitude: "",
      landmarkLongitude: "",
    },
  },
  leadDetail: {
    isFetching: true,
    data: null,
  },
  drawerOpen: false,
  unqualifiedReason: [],
  sendBackReasonList: [],
  timeSlotList: [],
  loanLockers: [],
  noteList: [],
  paginationData: {
    pageNo: Number(getValueFromQueryParams("leadPageNo") || 1),
    pageSize: 10,
    totalCount: 0,
  },
  isFetching: false,
  error: null,
  isFetchingState: {
    noteList: false,
    closeLMJob: false,
    getLocation: false,
  },
  googleLocations: {
    address: null,
    landmark: null,
  },
  blacklistedAddressList: [],
  byPassPacketScanningList: {
    packetDetailByRM: [],
    pledgeOrnamentDetail: [],
    crDocument: [],
  },
  loading: {
    getByPassPacketScanningList: false,
    approveBypassScanning: false,
    getLockerByLeadId: false,
    getDeliverySlots: false,
    getDeliverySlotChangeableDate: false,
    updateDeliverySlot: false,
    createRmJob: false,
    updateRmJob: false,
    validateCustomerDpd: false,
  },
  errors: {
    getByPassPacketScanningList: null,
    approveBypassScanning: null,
    getLockerByLeadId: null,
    getDeliverySlots: null,
    getDeliverySlotChangeableDate: null,
    updateDeliverySlot: null,
    getLocationFromAddress: null,
    createRmJob: null,
    updateRmJob: null,
    validateCustomerDpd: null,
  },
  modals: {
    bypassPacketScanning: false,
    updateDeliverySlots: false,
  },
  leadCreditDetails: {
    isFetching: true,
    data: null,
  },
  leadCreditReportStatus: {
    isFetching: true,
    data: null,
  },
  deliverySlots: [],
  deliverySlotChangeableDate: null,
  validateCustomerDpdDto: {
    errorMessage: null,
    allowLoanBooking: false,
  },
};

export const schedulingSlice = createSlice({
  name: "unscheduled",
  initialState,
  reducers: {
    resetSchedulingState: state => {
      state.leadList.isFetching = false;
      state.leadList.data = [];
      state.loanServicingJobList.isFetching = false;
      state.leadDetail.isFetching = false;
      state.leadDetail.data = null;
      state.unqualifiedReason = [];
      state.isFetching = false;
      state.error = null;
      state.drawerOpen = false;
    },
    resetDrawerState: state => {
      state.leadDetail = initialState.leadDetail;
      state.leadCreditReportStatus = initialState.leadCreditReportStatus;
      state.noteList = initialState.noteList;
      state.googleLocations = initialState.googleLocations;
      state.blacklistedAddressList = initialState.blacklistedAddressList;
    },
    refreshLSJobList: state => {
      state.loanServicingJobList.refreshState = !state.loanServicingJobList.refreshState;
    },
    toggleDrawerOpen: (state, action: PayloadAction<boolean>) => {
      if (!action.payload) {
        state.loanServicingJobList.selectedJob = null;
      }
      state.drawerOpen = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.paginationData.pageNo = action.payload;
    },
    setRowSize: (state, action: PayloadAction<number>) => {
      state.paginationData.pageSize = action.payload;
    },
    closeSuccessDialog: state => {
      state.lsJobAssignAgent.success = false;
    },
    resetLsAssignJobData: state => {
      state.lsJobAssignAgent.data = initialState.lsJobAssignAgent.data;
    },
    setLSSelectedJob: (state, action: PayloadAction<number>) => {
      state.loanServicingJobList.selectedJob =
        state.loanServicingJobList.data.find(job => job.crId === action.payload) || null;
    },
    toggleModals: (
      state,
      action: PayloadAction<{
        type: keyof UnscheduledState["modals"];
        value: boolean;
      }>,
    ) => {
      state.modals[action.payload.type] = action.payload.value;
    },
  },
  extraReducers: {
    [unscheduledActions.getLeadList.type]: state => {
      state.leadList.isFetching = true;
    },
    [unscheduledActions.getLeadListSuccess.type]: (state, action) => {
      state.leadList.isFetching = false;
      state.paginationData.pageNo = action.payload.leadList.pageNo || 1;
      state.paginationData.pageSize = action.payload.leadList.pageSize || 10;
      state.paginationData.totalCount = action.payload.leadList.totalCount || 0;
      state.leadList.data = action.payload.leadList.result;
    },
    [unscheduledActions.getLeadListFailure.type]: (state, action) => {
      state.leadList.isFetching = false;
      state.error = action.payload.error;
    },
    [unscheduledActions.getLoanServicingJobList.type]: state => {
      state.loanServicingJobList.isFetching = true;
    },
    [unscheduledActions.updateLSJobAssignAgentData.type]: (state, action) => {
      state.lsJobAssignAgent.data = action.payload;
    },
    [unscheduledActions.getLoanServicingJobListSuccess.type]: (state, action) => {
      state.loanServicingJobList.isFetching = false;
      state.paginationData.totalCount = action.payload.loanServicingJobList?.length || 0;
      state.loanServicingJobList.data = action.payload.loanServicingJobList;
    },
    [unscheduledActions.getLoanServicingJobListFailure.type]: (state, action) => {
      state.loanServicingJobList.isFetching = false;
      state.error = action.payload.error;
    },
    [unscheduledActions.getLeadDetail.type]: state => {
      state.leadDetail.isFetching = true;
      state.leadDetail.data = null;
      state.googleLocations = {
        address: null,
        landmark: null,
      };
    },
    [unscheduledActions.getLeadDetailSuccess.type]: (state, action) => {
      state.leadDetail.isFetching = false;
      state.leadDetail.data = action.payload.leadDetail;
    },
    [unscheduledActions.getLeadDetailFailure.type]: (state, action) => {
      state.leadDetail.isFetching = false;
      state.error = action.payload.error;
    },
    [unscheduledActions.getUnqualifiedReasons.type]: (state, action) => {
      state.isFetching = true;
    },
    [unscheduledActions.getUnqualifiedReasonsSuccess.type]: (state, action) => {
      state.unqualifiedReason = action.payload.reasons;
      state.isFetching = false;
    },
    [unscheduledActions.getUnqualifiedReasonsFailure.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },
    [unscheduledActions.getLoanLockers.type]: (state, action) => {
      state.isFetching = true;
    },
    [unscheduledActions.getLoanLockersSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.loanLockers = action.payload.loanLockers;
    },
    [unscheduledActions.getLoanLockersFailure.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },
    [unscheduledActions.updateLeadDetail.type]: state => {
      state.isFetching = true;
    },
    [unscheduledActions.updateLeadDetailSuccess.type]: (state, action) => {
      state.leadDetail.data = {
        ...state.leadDetail.data,
        ...action.payload.data,
      };
      const oldLeadList: LeadInterface[] = state.leadList.data;
      const leadIndex = oldLeadList.findIndex(
        (l: LeadInterface) => l.id === state.leadDetail.data!.id,
      );
      if (leadIndex !== -1) {
        state.leadList.data = [...oldLeadList.slice(0, leadIndex)];
        if ("keepLeadInList" in action.payload.data && action.payload.data.keepLeadInList) {
          state.leadList.data.push({
            ...oldLeadList[leadIndex],
            ...action.payload.data,
          });
        }
        state.leadList.data = [
          ...state.leadList.data,
          ...oldLeadList.slice(leadIndex + 1, oldLeadList.length),
        ];
      }
      state.error = null;
      state.isFetching = false;
    },
    [unscheduledActions.updateLeadDetailFailure.type]: (state, action) => {
      state.error = action.payload.error;
      state.isFetching = false;
    },
    [unscheduledActions.createJobFromLead.type]: state => {
      state.isFetching = true;
    },
    [unscheduledActions.createJobFromLeadSuccess.type]: state => {
      state.isFetching = false;
      const oldLeadList: LeadInterface[] = state.leadList.data;
      const leadIndex = oldLeadList.findIndex(
        (l: LeadInterface) => l.id === state.leadDetail.data!.id,
      );
      if (leadIndex !== -1) {
        state.leadList.data = [
          ...oldLeadList.slice(0, leadIndex),
          ...oldLeadList.slice(leadIndex + 1, oldLeadList.length),
        ];
      }
    },
    [unscheduledActions.createJobFromLeadFailure.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },
    [unscheduledActions.getSendBackReasonList.type]: state => {
      state.isFetching = true;
    },
    [unscheduledActions.getSendBackReasonListSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.sendBackReasonList = action.payload.reasonList;
    },
    [unscheduledActions.getSendBackReasonListFailure.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },
    [unscheduledActions.getPickupLocation.type]: (state, action) => {
      state.pickupLocation.isFetching = true;
    },
    [unscheduledActions.getPickupLocationSuccess.type]: (state, action) => {
      state.pickupLocation.isFetching = false;
      state.pickupLocation.data = action.payload.data;
    },
    [unscheduledActions.getPickupLocationFailure.type]: (state, action) => {
      state.pickupLocation.isFetching = false;
      state.error = action.payload.error;
    },
    [unscheduledActions.createRMJobFailure.type]: (state, action) => {
      state.lsJobAssignAgent.isFetching = false;
      state.error = action.payload.error;
      state.errors.createRmJob = action.payload.error?.message || null;
    },
    [unscheduledActions.createRMJob.type]: state => {
      state.lsJobAssignAgent.isFetching = true;
      state.loading.createRmJob = true;
      state.errors.createRmJob = null;
    },
    [unscheduledActions.createRMJobSuccess.type]: state => {
      state.lsJobAssignAgent.isFetching = false;
      state.lsJobAssignAgent.success = true;
      state.errors.createRmJob = null;
      state.loading.createRmJob = false;
      state.loanServicingJobList.refreshState = !state.loanServicingJobList.refreshState;
    },
    [unscheduledActions.updateRmJob.type]: state => {
      state.isFetching = true;
      state.loading.updateRmJob = true;
      state.errors.updateRmJob = null;
    },
    [unscheduledActions.updateRmJobSuccess.type]: state => {
      state.isFetching = false;
      state.errors.updateRmJob = null;
      state.loanServicingJobList.refreshState = !state.loanServicingJobList.refreshState;
    },
    [unscheduledActions.updateRmJobFailure.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
      state.errors.updateRmJob = action.payload.error?.message || null;
    },
    [unscheduledActions.deleteRmJob.type]: state => {
      state.isFetching = true;
    },
    [unscheduledActions.deleteRmJobSuccess.type]: state => {
      state.isFetching = false;
      state.loanServicingJobList.refreshState = !state.loanServicingJobList.refreshState;
    },
    [unscheduledActions.deleteRmJobFailure.type]: state => {
      state.isFetching = false;
    },
    [unscheduledActions.getTimeSlotList.type]: state => {
      state.isFetching = true;
      state.error = null;
    },
    [unscheduledActions.getTimeSlotListFailure.type]: state => {
      state.isFetching = false;
    },
    [unscheduledActions.getTimeSlotListSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.timeSlotList = action.payload?.response;
    },
    [unscheduledActions.getNoteList.type]: state => {
      state.isFetchingState.noteList = true;
    },
    [unscheduledActions.getNoteListSuccess.type]: (state, action) => {
      state.isFetchingState.noteList = false;
      state.noteList = action.payload.noteList;
    },
    [unscheduledActions.getNoteListFailure.type]: (state, action) => {
      state.isFetchingState.noteList = false;
      state.error = action.payload.error;
    },
    [unscheduledActions.updateNoteList.type]: state => {
      state.isFetchingState.noteList = true;
    },
    [unscheduledActions.updateNoteListSuccess.type]: (state, action) => {
      state.isFetchingState.noteList = false;
      const newNoteList = [...state.noteList];
      newNoteList.unshift(action.payload.note);
      state.noteList = newNoteList;
    },
    [unscheduledActions.updateNoteListFailure.type]: (state, action) => {
      state.isFetchingState.noteList = false;
      state.error = action.payload.error;
    },
    [unscheduledActions.getLocationFromAddress.type]: state => {
      state.isFetchingState.getLocation = true;
      state.blacklistedAddressList = [];
      state.errors.getLocationFromAddress = null;
    },
    [unscheduledActions.getLocationFromAddressSuccess.type]: (state, action) => {
      state.isFetchingState.getLocation = true;
      state.errors.getLocationFromAddress = null;
      const {type, addressDetails} = action.payload;
      state.googleLocations[type] = addressDetails.geocodeResultDto;
      state.blacklistedAddressList = addressDetails.fwcLoanBlacklistValidateResponseDtoList;

      state.lsJobAssignAgent.data = {
        ...state.lsJobAssignAgent.data,
        addressLatitude: addressDetails.geocodeResultDto.latitude,
        addressLongitude: addressDetails.geocodeResultDto.longitude,
        landmarkLatitude: addressDetails.geocodeResultDto.latitude,
        landmarkLongitude: addressDetails.geocodeResultDto.longitude,
      };
    },
    [unscheduledActions.getLocationFromAddressFailure.type]: (state, action) => {
      state.isFetchingState.getLocation = true;
      state.error = action.payload.error;
      state.errors.getLocationFromAddress = action.payload.error;
    },
    [unscheduledActions.closeLMJob.type]: state => {
      state.isFetchingState.closeLMJob = true;
    },
    [unscheduledActions.closeLMJobSuccess.type]: state => {
      state.isFetchingState.closeLMJob = false;
      state.drawerOpen = false;
    },
    [unscheduledActions.closeLMJobFailure.type]: (state, action) => {
      state.isFetchingState.closeLMJob = false;
      state.error = action.payload.error;
    },
    [unscheduledActions.getBypassPacketScanningList.type]: state => {
      state.loading.getByPassPacketScanningList = true;
      state.errors.getByPassPacketScanningList = null;
      state.byPassPacketScanningList = initialState.byPassPacketScanningList;
    },
    [unscheduledActions.getBypassPacketScanningListSuccess.type]: (state, action) => {
      state.loading.getByPassPacketScanningList = false;
      state.errors.getByPassPacketScanningList = null;
      const {
        packetDetailByRM,
        pledgeOrnamentDetail,
        crDocumentResponseDtoList,
      } = action.payload.response;

      state.byPassPacketScanningList = {
        ...state.byPassPacketScanningList,
        packetDetailByRM,
        pledgeOrnamentDetail,
        crDocument: crDocumentResponseDtoList,
      };
    },
    [unscheduledActions.getBypassPacketScanningListFailure.type]: (state, action) => {
      state.loading.getByPassPacketScanningList = false;
      state.modals.bypassPacketScanning = false;
      state.errors.getByPassPacketScanningList = action.payload.error;
      state.byPassPacketScanningList = initialState.byPassPacketScanningList;
    },
    [unscheduledActions.approveBypassScanning.type]: state => {
      state.loading.approveBypassScanning = true;
      state.errors.approveBypassScanning = null;
    },
    [unscheduledActions.approveBypassScanningSuccess.type]: state => {
      state.loading.approveBypassScanning = false;
    },
    [unscheduledActions.approveBypassScanningFailure.type]: (state, action) => {
      state.loading.approveBypassScanning = false;
      state.errors.approveBypassScanning = action.payload.error;
    },
    [unscheduledActions.getLeadCreditDetails.type]: state => {
      state.leadCreditDetails.isFetching = true;
    },
    [unscheduledActions.getLeadCreditDetailsSuccess.type]: (state, action) => {
      state.leadCreditDetails.data = action.payload.response;
      state.leadCreditDetails.isFetching = false;
    },
    [unscheduledActions.getLeadCreditDetailsFailure.type]: (state, action) => {
      state.error = action.payload.error;
      state.leadCreditDetails.isFetching = false;
    },
    [unscheduledActions.getLeadCreditReportStatus.type]: state => {
      state.leadCreditReportStatus.data = null;
      state.leadCreditReportStatus.isFetching = true;
    },
    [unscheduledActions.getLeadCreditReportStatusSuccess.type]: (state, action) => {
      state.leadCreditReportStatus.data = action.payload.response;
      state.leadCreditReportStatus.isFetching = false;
    },
    [unscheduledActions.getLeadCreditReportStatusFailure.type]: (state, action) => {
      state.error = action.payload.error;
      state.leadCreditReportStatus.isFetching = false;
    },
    [unscheduledActions.getLockerByLeadId.type]: state => {
      state.loading.getLockerByLeadId = true;
      state.loanLockers = initialState.loanLockers;
      state.errors.getLockerByLeadId = initialState.errors.getLockerByLeadId;
    },
    [unscheduledActions.getLockerByLeadIdSuccess.type]: (state, action) => {
      state.loading.getLockerByLeadId = false;
      const {crLoanLockerDtoList, preferredVaultId} = action.payload.response;
      state.loanLockers = crLoanLockerDtoList;
      state.leadDetail.data = {
        ...state.leadDetail.data,
        preferredVaultId: preferredVaultId,
      } as LeadInterface;
    },
    [unscheduledActions.getLockerByLeadIdFailure.type]: (state, action) => {
      state.loading.getLockerByLeadId = false;
      state.errors.getLockerByLeadId = action.payload.error;
    },
    [unscheduledActions.getGoldDeliverySlots.type]: state => {
      state.loading.getDeliverySlots = true;
      state.errors.getDeliverySlots = null;
      state.deliverySlots = initialState.deliverySlots;
    },
    [unscheduledActions.getGoldDeliverySlotsSuccess.type]: (state, action) => {
      state.loading.getDeliverySlots = false;
      state.errors.getDeliverySlots = null;
      state.deliverySlots = action.payload.response;
    },
    [unscheduledActions.getGoldDeliverySlotsFailure.type]: (state, action) => {
      state.loading.getDeliverySlots = false;
      state.errors.getDeliverySlots = action.payload.error;
    },
    [unscheduledActions.getGoldDeliverySlotChangeableDate.type]: state => {
      state.loading.getDeliverySlotChangeableDate = true;
      state.errors.getDeliverySlotChangeableDate = null;
      state.deliverySlotChangeableDate = null;
    },
    [unscheduledActions.getGoldDeliverySlotChangeableDateSuccess.type]: (state, action) => {
      state.loading.getDeliverySlotChangeableDate = false;
      state.errors.getDeliverySlotChangeableDate = null;
      state.deliverySlotChangeableDate = action.payload.response;
    },
    [unscheduledActions.getGoldDeliverySlotChangeableDateFailure.type]: (state, action) => {
      state.loading.getDeliverySlotChangeableDate = false;
      state.errors.getDeliverySlotChangeableDate = action.payload.error;
      state.deliverySlotChangeableDate = null;
    },
    [unscheduledActions.updateGoldDeliverySlot.type]: state => {
      state.loading.updateDeliverySlot = true;
      state.errors.updateDeliverySlot = null;
    },
    [unscheduledActions.updateGoldDeliverySlotSuccess.type]: state => {
      state.loading.updateDeliverySlot = false;
      state.errors.updateDeliverySlot = null;
      state.modals.updateDeliverySlots = false;
      state.deliverySlotChangeableDate = null;
    },
    [unscheduledActions.updateGoldDeliverySlotFailure.type]: (state, action) => {
      state.loading.updateDeliverySlot = false;
      state.errors.updateDeliverySlot = action.payload.error;
    },
    [unscheduledActions.removeAgentJob.type]: state => {
      state.isFetching = true;
    },
    [unscheduledActions.removeAgentJobSuccess.type]: state => {
      state.isFetching = false;
      state.loanServicingJobList.refreshState = !state.loanServicingJobList.refreshState;
    },
    [unscheduledActions.removeAgentJobFailure.type]: state => {
      state.isFetching = false;
    },

    [unscheduledActions.validateCustomerDpd.type]: state => {
      state.validateCustomerDpdDto = initialState.validateCustomerDpdDto;
      state.loading.validateCustomerDpd = true;
    },
    [unscheduledActions.validateCustomerDpdSuccess.type]: (state, action)=> {
      const {errorMessage, allowLoanBooking} = action.payload.response;
      state.loading.validateCustomerDpd = false;
      state.validateCustomerDpdDto.allowLoanBooking = allowLoanBooking;
      state.validateCustomerDpdDto.errorMessage = errorMessage;
    },
    [unscheduledActions.validateCustomerDpdFailure.type]: (state, action) => {
      state.loading.validateCustomerDpd = false;
      state.errors.validateCustomerDpd = action.payload.error;
    },
  },
});

const {
  resetSchedulingState,
  toggleDrawerOpen,
  setCurrentPage,
  refreshLSJobList,
  setRowSize,
  closeSuccessDialog,
  resetLsAssignJobData,
  setLSSelectedJob,
  toggleModals,
  resetDrawerState,
} = schedulingSlice.actions;

export default schedulingSlice.reducer;
export {
  resetSchedulingState,
  toggleDrawerOpen,
  setCurrentPage,
  initialState,
  refreshLSJobList,
  setRowSize,
  closeSuccessDialog,
  resetLsAssignJobData,
  setLSSelectedJob,
  toggleModals,
  resetDrawerState,
};
