import {LoanOfferPfDetailDto} from "./../types";
import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GoldApiError} from "app/typings/api/goldApi.types";
import renewRebookActions from "./actions";
import {getValidTime} from "_metronic/utils/moment";
import {
  CloseStatus,
  CMAdjustmentRenewalDetail,
  CMConfig,
  CMOfferDetails,
  CustomerFinancials,
  CustomerRequirements,
  GoldRate,
  LoadingErrorState,
  LoanClosureDetail,
  LoanTypes,
  NetPayable,
  OrnamentDetail,
  OurOffer,
  PaymentDetails,
  RenewalConfig,
  RenewRebookOfferDetail,
  RepaymentDetails,
  ServiceRequestLoanPaymentDetailDto,
  UserAcceptedOffer,
  VersionDetails,
} from "./types";
import {createActionsInitialState, setActionError, setActionLoading, setActionSuccess} from "app/store/redux.utils";
import {RootState} from "app/store/store";

export interface RenewRebookState {
  error: {
    saveCustomerReqDetails: GoldApiError | null;
    getOurOffer: GoldApiError | null;
    getVersionHistory: GoldApiError | null;
    updateShareStatus: GoldApiError | null;
    getNetPayable: GoldApiError | null;
    getOrnamentDetails: GoldApiError | null;
    updateOrnamentDetails: GoldApiError | null;
    getCustomerFinancials: GoldApiError | null;
    getCustomerRequirements: GoldApiError | null;
    getRepaymentHistory: GoldApiError | null;
    saveCMOfferDetails: GoldApiError | null;
    getCMOfferDetails: GoldApiError | null;
    getRenewRebookConfig: GoldApiError | null;
    getCMConfig: GoldApiError | null;
    getPaymentDetail: GoldApiError | null;
    getLoanRenewalClosureDetail: GoldApiError | null;
    closeLoanRenewal: GoldApiError | null;
    getLoanRenewalMappedPayments: GoldApiError | null;
    updateRoi: GoldApiError | null;
    getUserAcceptedOffer: GoldApiError | null;
    getGoldRate: GoldApiError | null;
    getLoanRenewalStatus: GoldApiError | null;
    getCMAdjustmentRenewalDetail: GoldApiError | null;
    updateCMAdjustmentRenewalDetail: GoldApiError | null;
    checkIfOfferEngineApplicable: GoldApiError | null;
    getOfferList: GoldApiError | null;
  };
  loading: {
    saveCustomerReqDetails: boolean;
    getOurOffer: boolean;
    getVersionHistory: boolean;
    updateShareStatus: boolean;
    getNetPayable: boolean;
    getOrnamentDetails: boolean;
    updateOrnamentDetails: boolean;
    getCustomerFinancials: boolean;
    getCustomerRequirements: boolean;
    getRepaymentHistory: boolean;
    saveCMOfferDetails: boolean;
    getCMOfferDetails: boolean;
    getRenewRebookConfig: boolean;
    getCMConfig: boolean;
    getPaymentDetail: boolean;
    getLoanRenewalClosureDetail: boolean;
    closeLoanRenewal: boolean;
    getLoanRenewalMappedPayments: boolean;
    updateRoi: boolean;
    getUserAcceptedOffer: boolean;
    getGoldRate: boolean;
    getLoanRenewalStatus: boolean;
    getCMAdjustmentRenewalDetail: boolean;
    updateCMAdjustmentRenewalDetail: boolean;
    checkIfOfferEngineApplicable: boolean;
    getOfferList: boolean;
  };
  success: {
    updateShareStatus: boolean;
  }
  customerReqFormActiveStep: number;
  completedSteps: {
    [k: number]: boolean;
  };
  ourOfferDetails: OurOffer | null;
  versionHistory: VersionDetails[];
  netPayable: NetPayable | null;
  ornamentList: OrnamentDetail[];
  totalGoldValuation: number;
  customerFinancials: CustomerFinancials;
  customerRequirements: CustomerRequirements | null;
  repaymentHistory: RepaymentDetails;
  cmOfferDetails: CMOfferDetails | null;
  renewalConfig: RenewalConfig;
  cmConfig: CMConfig | null;
  paymentDetail: PaymentDetails | null;
  loanRenewalClosureDetail: {
    GL: LoanClosureDetail;
    PL: LoanClosureDetail;
  };
  listReload: boolean;
  loanRenewalMappedPayments: ServiceRequestLoanPaymentDetailDto;
  userAcceptedOffer: UserAcceptedOffer | null;
  goldRate: GoldRate;
  CMAdjustmentRenewalDetail: CMAdjustmentRenewalDetail | null;
  openModals: {
    cmAdjustmentRenewalDetail: boolean;
  };
  forceSubmitCmForm: boolean;
  offerList: ReturnType<typeof offerListAdapter.getInitialState>;
  isOfferEngineApplicable: boolean;
  isOfferListVisibleToConsumer: boolean;
  isOfferSelected: boolean;
  actions: ReturnType<typeof createActionsInitialState>;
}

export type RenewRebookActions =
  | "checkIfOfferEngineApplicable"
  | "getOfferList"
  | "selectOfferForCustomer";


const offerListAdapter = createEntityAdapter<RenewRebookOfferDetail>({
  selectId: (entity) => entity.id,
});

const initialState: RenewRebookState = {
  error: {
    saveCustomerReqDetails: null,
    getOurOffer: null,
    getVersionHistory: null,
    updateShareStatus: null,
    getNetPayable: null,
    getOrnamentDetails: null,
    updateOrnamentDetails: null,
    getCustomerFinancials: null,
    getCustomerRequirements: null,
    getRepaymentHistory: null,
    saveCMOfferDetails: null,
    getCMOfferDetails: null,
    getRenewRebookConfig: null,
    getCMConfig: null,
    getPaymentDetail: null,
    getLoanRenewalClosureDetail: null,
    closeLoanRenewal: null,
    getLoanRenewalMappedPayments: null,
    updateRoi: null,
    getUserAcceptedOffer: null,
    getGoldRate: null,
    getLoanRenewalStatus: null,
    getCMAdjustmentRenewalDetail: null,
    updateCMAdjustmentRenewalDetail: null,
    checkIfOfferEngineApplicable: null,
    getOfferList: null,
  },
  loading: {
    saveCustomerReqDetails: false,
    getOurOffer: false,
    getVersionHistory: false,
    updateShareStatus: false,
    getNetPayable: false,
    getOrnamentDetails: false,
    updateOrnamentDetails: false,
    getCustomerFinancials: false,
    getCustomerRequirements: false,
    getRepaymentHistory: false,
    saveCMOfferDetails: false,
    getCMOfferDetails: false,
    getRenewRebookConfig: false,
    getCMConfig: false,
    getPaymentDetail: false,
    getLoanRenewalClosureDetail: false,
    closeLoanRenewal: false,
    getLoanRenewalMappedPayments: false,
    updateRoi: false,
    getUserAcceptedOffer: false,
    getGoldRate: false,
    getLoanRenewalStatus: false,
    getCMAdjustmentRenewalDetail: false,
    updateCMAdjustmentRenewalDetail: false,
    checkIfOfferEngineApplicable: true,
    getOfferList: false,
  },
  success: {
    updateShareStatus: false,
  },
  listReload: false,
  customerReqFormActiveStep: 0,
  completedSteps: {},
  ourOfferDetails: null,
  versionHistory: [],
  netPayable: null,
  ornamentList: [],
  totalGoldValuation: 0,
  customerFinancials: {
    cibilScore: null,
    annualIncome: null,
  },
  repaymentHistory: {
    isPersonalLoanTaken: false,
    loanId: 0,
    loanInstallmentList: [],
  },
  customerRequirements: null,
  cmOfferDetails: {
    plAnnualRoi: 0,
    glAnnualRoi: 0,
    cmPersonalLoanAmount: 0,
    cmGoldLoanLtv: 0,
    cmGoldLoanAmount: 0,
    cmPersonalLoanPercentage: 0,
    marginMoney: 0,
    loanRenewalAmount: 0,
    additionalTopUpAmount: 0,
    goldPriceAdjustment: 0,
    isEditable: true,
    plMonthlyRoi: 0,
    glMonthlyRoi: 0,
    checkIfPlApplicable: false,
    lmGoldValuation: 0,
    remarks: "",
    isAdjustmentLimitBreached: false,
    loanSchemeType: "",
    isEligibleForAgriLoans: false,
    actualGoldLoanPfApplicableIncludingGst: 0,
    adjustedActualPfAmountWithTopUp:0,
  },
  renewalConfig: {
    renewalReasonList: [],
    goldLoanMarketingKaratList: [],
    loanRenewalCompetitorList: [],
  },
  cmConfig: null,
  paymentDetail: null,
  loanRenewalClosureDetail: {
    GL: {
      igClosureAmount: null,
      igClosureDate: getValidTime().valueOf(),
      igRenewalAmount: null,
      lenderClosureAmount: null,
      lenderClosureDate: getValidTime().valueOf(),
      dummyReceiptAmount: null,
      waiveOffClosureAmount: null,
      receivedRenewalAmount: null,
      receivedPartReleaseAmount: null,
      loanRenewalCloseStatus: null,
      isClosureAttempted: false,
      errorMessage: null,
      showGlSubventionDummyReceiptCreationButton: false,
    },
    PL: {
      igClosureAmount: null,
      igClosureDate: getValidTime().valueOf(),
      igRenewalAmount: null,
      lenderClosureAmount: null,
      lenderClosureDate: getValidTime().valueOf(),
      dummyReceiptAmount: null,
      waiveOffClosureAmount: null,
      receivedRenewalAmount: null,
      receivedPartReleaseAmount: null,
      loanRenewalCloseStatus: null,
      isClosureAttempted: false,
      showGlSubventionDummyReceiptCreationButton: false,
      errorMessage: null,
    },
  },
  loanRenewalMappedPayments: {
    totalOutstandingPayment: null,
    totalPaymentAmount: null,
    totalSubventionAmount: null,
    serviceRequestMappedPaymentList: [],
    serviceRequestLoanPaymentDetailList: [],
  },
  userAcceptedOffer: null,
  goldRate: {
    goldRateDetailAtLoanCreationTime: null,
    liveGoldRateDetail: null,
  },
  CMAdjustmentRenewalDetail: null,
  openModals: {
    cmAdjustmentRenewalDetail: false,
  },
  forceSubmitCmForm: false,
  offerList: offerListAdapter.getInitialState(),
  isOfferEngineApplicable: true,
  isOfferListVisibleToConsumer: false,
  isOfferSelected: false,
  actions: createActionsInitialState([
    "checkIfOfferEngineApplicable",
    "getOfferList",
    "selectOfferForCustomer",
  ]),
};

const renewRebookSlice = createSlice({
  name: "renewRebook",
  initialState,
  reducers: {
    reloadList: (state) => {
      state.listReload = !state.listReload;
    },
    updateActiveStep: (state, action: PayloadAction<number>) => {
      state.customerReqFormActiveStep = action.payload;
    },
    setCompletedSteps: (state, action: PayloadAction<number>) => {
      state.completedSteps[action.payload] = true;
    },
    resetCompletedSteps: (state) => {
      state.completedSteps = {};
    },
    setLoanRenewalClosureDetail: (
      state,
      action: PayloadAction<{
        type: LoanTypes;
        data: Partial<LoanClosureDetail>;
      }>,
    ) => {
      state.loanRenewalClosureDetail[action.payload.type] = {
        ...state.loanRenewalClosureDetail[action.payload.type],
        ...action.payload.data,
      };
    },
    resetLoanRenewalClosureDetail: (
      state,
      action: PayloadAction<{type: LoanTypes}>,
    ) => {
      state.loanRenewalClosureDetail[action.payload.type] = {
        ...initialState.loanRenewalClosureDetail[action.payload.type],
        lenderClosureDate: getValidTime().valueOf(),
        igClosureDate: getValidTime().valueOf(),
      };
    },
    setLoanRenewalCloseStatus: (
      state,
      action: PayloadAction<{type: LoanTypes; value: CloseStatus}>,
    ) => {
      state.loanRenewalClosureDetail[
        action.payload.type
      ].loanRenewalCloseStatus = action.payload.value;
    },
    toggleModals: (
      state,
      action: PayloadAction<{
        modalName: keyof RenewRebookState["openModals"];
        value: boolean;
      }>,
    ) => {
      state.openModals[action.payload.modalName] = action.payload.value;
    },
    setForceSubmitCmForm: (state, action: PayloadAction<boolean>) => {
      state.forceSubmitCmForm = action.payload;
    },
    offerEngineApplicabilityReceived: (
      state,
      action: PayloadAction<{
        isOfferEngineApplicable: boolean
        isOfferListVisibleToConsumer: boolean
        isOfferSelected: boolean
      }>,
    ) => {
      state.isOfferEngineApplicable = action.payload.isOfferEngineApplicable;
      state.isOfferListVisibleToConsumer = action.payload.isOfferListVisibleToConsumer;
      state.isOfferSelected = action.payload.isOfferSelected;
    },
    offerListReceived: (
      state,
      action: PayloadAction<{
        fwcServiceRequestId: number;
        offerResponseDtoList: any;
      }>,
    ) => {
      const {fwcServiceRequestId, offerResponseDtoList: offerList} = action.payload;
      const modifiedOffers = offerList.map((offer: any) => ({
        ...offer,
        requestId: fwcServiceRequestId,
        renewalAmountBreakDetailDto: {
          ...offer?.renewalAmountBreakDetailDto,
          goldPriceAdjustment: offer?.renewalAmountBreakDetailDto?.goldPriceAdjustmentDto,
          interestDueDate: offer?.renewalAmountBreakDetailDto?.interestDueDateDto,
          loanModifiedAmount: offer?.renewalAmountBreakDetailDto?.loanModifiedAmountDto,
        },
      }));

      offerListAdapter.setAll(state.offerList, modifiedOffers);
    },
    resetOfferEngine: (state) => {
      state.isOfferEngineApplicable = true;
    },
    setOfferDisplayedOnConsumerApp: (state) => {
      state.isOfferListVisibleToConsumer = true;
    },
    setActionLoading,
    setActionSuccess,
    setActionError,
  },
  extraReducers: (builder) => {
    builder
      .addCase(renewRebookActions.saveCustomerReqDetails, (state) => {
        state.loading.saveCustomerReqDetails = true;
      })
      .addCase(
        renewRebookActions.saveCustomerReqDetailsSuccess,
        (state, action) => {
          state.loading.saveCustomerReqDetails = false;
          state.completedSteps[state.customerReqFormActiveStep] = true;
          state.customerReqFormActiveStep += 1;
        },
      )
      .addCase(
        renewRebookActions.saveCustomerReqDetailsFailure,
        (state, action) => {
          state.loading.saveCustomerReqDetails = false;
          state.error.saveCustomerReqDetails = action.payload.error;
        },
      )
      .addCase(renewRebookActions.getOurOffer, (state) => {
        state.loading.getOurOffer = true;
        state.ourOfferDetails = initialState.ourOfferDetails;
      })
      .addCase(renewRebookActions.getOurOfferSuccess, (state, action) => {
        state.loading.getOurOffer = false;
        state.ourOfferDetails = action.payload.ourOffer;
      })
      .addCase(renewRebookActions.getOurOfferFailure, (state, action) => {
        state.loading.getOurOffer = false;
        state.error.getOurOffer = action.payload.error;
      })
      .addCase(renewRebookActions.getVersionHistory, (state) => {
        state.loading.getVersionHistory = true;
        state.versionHistory = initialState.versionHistory;
      })
      .addCase(renewRebookActions.getVersionHistorySuccess, (state, action) => {
        state.loading.getVersionHistory = false;
        state.versionHistory = action.payload.versionHistory.map((i: any) => ({
          updatedAt: i.updatedAt,
          createdAt: i.createdAt,
          customerRequirement: i.customerRequirementDto,
          offerDetail: i.offerDetailDto,
          renewalAmountBreakDetail: {
            ...i.renewalAmountBreakDetailDto,
            goldPriceAdjustment:
              i.renewalAmountBreakDetailDto.goldPriceAdjustmentDto,
            interestDueDate: i.renewalAmountBreakDetailDto.interestDueDateDto,
            loanModifiedAmount: i.loanModifiedAmountDto,
          },
        }));
      })
      .addCase(renewRebookActions.getVersionHistoryFailure, (state, action) => {
        state.loading.getVersionHistory = false;
        state.error.getVersionHistory = action.payload.error;
      })
      .addCase(renewRebookActions.updateShareStatus, (state) => {
        state.loading.updateShareStatus = true;
        state.success.updateShareStatus = false;
      })
      .addCase(renewRebookActions.updateShareStatusSuccess, (state, action) => {
        state.loading.updateShareStatus = false;
        state.success.updateShareStatus = true;
      })
      .addCase(renewRebookActions.updateShareStatusFailure, (state, action) => {
        state.loading.updateShareStatus = false;
        state.error.updateShareStatus = action.payload.error;
        state.success.updateShareStatus = false;
      })
      .addCase(renewRebookActions.getNetPayable, (state) => {
        state.loading.getNetPayable = true;
        state.netPayable = initialState.netPayable;
      })
      .addCase(renewRebookActions.getNetPayableSuccess, (state, action) => {
        state.loading.getNetPayable = false;
        const {
          payableAmount,
          totalInterestAmount,
          excessAmountPaid,
          goldPriceAdjustmentDto,
          interestDueDateDto,
          overdueAmountDetail,
          modifiedLoanAmount,
          goldPriceAdjustmentAmount,
          loanModifiedAmountDto,
          totalOverdueAmount,
          totalSubventionRecoveryAmount,
          fwcLoanForeclosureChargeDetailDto,
          totalAdjustedInterestAmount,
          totalWaivedOffInterestAmount,
          fwcSrRenewalOfferPfDetailDto,
          totalPublicationChargeAmount,
          fwcLoanRenewalPublicationChargeDetailDto,
        } = action.payload.response;

        state.netPayable = {
          payableAmount: payableAmount,
          totalInterestAmount,
          excessAmountPaid,
          goldPriceAdjustment: goldPriceAdjustmentDto,
          interestDueDate: interestDueDateDto,
          overdueAmountDetail,
          modifiedLoanAmount,
          goldPriceAdjustmentAmount,
          loanModifiedAmount: loanModifiedAmountDto,
          totalOverdueAmount,
          totalSubventionRecoveryAmount,
          fwcLoanForeclosureChargeDetailDto,
          totalAdjustedInterestAmount,
          totalWaivedOffInterestAmount,
          fwcSrRenewalOfferPfDetailDto: fwcSrRenewalOfferPfDetailDto,
          totalPublicationChargeAmount,
          fwcLoanRenewalPublicationChargeDetailDto,
        };
      })
      .addCase(renewRebookActions.getNetPayableFailure, (state, action) => {
        state.loading.getNetPayable = false;
        state.error.getNetPayable = action.payload.error;
      })
      .addCase(renewRebookActions.getOrnamentsDetails, (state) => {
        state.loading.getOrnamentDetails = true;
        state.ornamentList = initialState.ornamentList;
      })
      .addCase(
        renewRebookActions.getOrnamentsDetailsSuccess,
        (state, action) => {
          const {
            serviceRequestCmOrnamentList,
            totalGoldValuation,
          } = action.payload.response;
          state.loading.getOrnamentDetails = false;
          state.ornamentList = serviceRequestCmOrnamentList;
          state.totalGoldValuation = totalGoldValuation;
        },
      )
      .addCase(
        renewRebookActions.getOrnamentsDetailsFailure,
        (state, action) => {
          state.loading.getOrnamentDetails = false;
          state.error.getOrnamentDetails = action.payload.error;
        },
      )
      .addCase(renewRebookActions.updateOrnamentsDetails, (state) => {
        state.loading.updateOrnamentDetails = true;
      })
      .addCase(
        renewRebookActions.updateOrnamentsDetailsSuccess,
        (state, action) => {
          state.loading.updateOrnamentDetails = false;
          const ornamentToUpdate = state.ornamentList.findIndex(
            (o) => o.crOrnamentId === action.payload.response.ornamentId,
          );
          if (ornamentToUpdate !== -1) {
            state.ornamentList[ornamentToUpdate].newOrnament = {
              ...state.ornamentList[ornamentToUpdate].newOrnament,
              grossWeight: action.payload.response.grossWeight,
              impurity: action.payload.response.impurity,
              purity: action.payload.response.purity,
              stoneWeightInGrams: action.payload.response.stoneWeightInGrams,
            };
          }
          state.totalGoldValuation = action.payload.response.totalGoldValuation;
        },
      )
      .addCase(
        renewRebookActions.updateOrnamentsDetailsFailure,
        (state, action) => {
          state.loading.updateOrnamentDetails = false;
          state.error.updateOrnamentDetails = action.payload.error;
        },
      )
      .addCase(renewRebookActions.getCustomerFinancials, (state) => {
        state.loading.getCustomerFinancials = true;
        state.customerFinancials = initialState.customerFinancials;
      })
      .addCase(
        renewRebookActions.getCustomerFinancialsSuccess,
        (state, action) => {
          state.loading.getCustomerFinancials = false;
          state.customerFinancials = action.payload.response;
        },
      )
      .addCase(
        renewRebookActions.getCustomerFinancialsFailure,
        (state, action) => {
          state.loading.getCustomerFinancials = false;
          state.error.getCustomerFinancials = action.payload.error;
        },
      )
      .addCase(renewRebookActions.getCustomerRequirements, (state) => {
        state.loading.getCustomerRequirements = true;
        state.customerRequirements = initialState.customerRequirements;
      })
      .addCase(
        renewRebookActions.getCustomerRequirementsSuccess,
        (state, action) => {
          state.loading.getCustomerRequirements = false;
          const {
            rebookCompetitorDetail,
            isCustomerRequirementAvailable,
          } = action.payload.response;

          state.customerRequirements = {
            ...action.payload.response,
            rebookCompetitorDetail: rebookCompetitorDetail
              ? {
                  ...rebookCompetitorDetail,
                  offeredAmount: rebookCompetitorDetail.amount,
                  attachmentList: rebookCompetitorDetail.attachmentList,
                }
              : null,
          };

          if (isCustomerRequirementAvailable) {
            state.customerReqFormActiveStep = 3;
            state.completedSteps = {
              0: true,
              1: true,
              2: true,
            };
          } else {
            state.customerReqFormActiveStep = 0;
            state.completedSteps = {
              0: false,
              1: false,
              2: false,
            };
          }
        },
      )
      .addCase(
        renewRebookActions.getCustomerRequirementsFailure,
        (state, action) => {
          state.loading.getCustomerRequirements = false;
          state.error.getCustomerRequirements = action.payload.error;
        },
      )
      .addCase(renewRebookActions.getRepaymentHistory, (state) => {
        state.loading.getRepaymentHistory = true;
        state.repaymentHistory = initialState.repaymentHistory;
      })
      .addCase(
        renewRebookActions.getRepaymentHistorySuccess,
        (state, action) => {
          const {
            loanId,
            isPersonalLoanTaken,
            loanInstallmentResponseDtoList,
          } = action.payload.repaymentHistory;
          state.loading.getRepaymentHistory = false;
          state.repaymentHistory = {
            loanId,
            isPersonalLoanTaken,
            loanInstallmentList: loanInstallmentResponseDtoList || [],
          };
        },
      )
      .addCase(
        renewRebookActions.getRepaymentHistoryFailure,
        (state, action) => {
          state.loading.getRepaymentHistory = false;
          state.error.getRepaymentHistory = action.payload.error;
        },
      )
      .addCase(renewRebookActions.saveCMOfferDetails, (state) => {
        state.loading.saveCMOfferDetails = true;
      })
      .addCase(
        renewRebookActions.saveCMOfferDetailsSuccess,
        (state, action) => {
          state.loading.saveCMOfferDetails = false;
          state.cmOfferDetails = action.payload.response;
          state.forceSubmitCmForm = false;
        },
      )
      .addCase(
        renewRebookActions.saveCMOfferDetailsFailure,
        (state, action) => {
          state.loading.saveCMOfferDetails = false;
          state.error.saveCMOfferDetails = action.payload.error;
          state.forceSubmitCmForm = false;
        },
      )
      .addCase(renewRebookActions.getCMOfferDetails, (state) => {
        state.loading.getCMOfferDetails = true;
        state.cmOfferDetails = initialState.cmOfferDetails;
      })
      .addCase(renewRebookActions.getCMOfferDetailsSuccess, (state, action) => {
        state.loading.getCMOfferDetails = false;
        if (action.payload.response) {
          state.cmOfferDetails = action.payload.response;
        }
      })
      .addCase(renewRebookActions.getCMOfferDetailsFailure, (state, action) => {
        state.loading.getCMOfferDetails = false;
        state.error.getCMOfferDetails = action.payload.error;
      })
      .addCase(renewRebookActions.getRenewRebookConfig, (state) => {
        state.loading.getRenewRebookConfig = true;
        state.renewalConfig = initialState.renewalConfig;
      })
      .addCase(
        renewRebookActions.getRenewRebookConfigSuccess,
        (state, action) => {
          state.loading.getRenewRebookConfig = false;
          state.renewalConfig = action.payload.response;
        },
      )
      .addCase(
        renewRebookActions.getRenewRebookConfigFailure,
        (state, action) => {
          state.loading.getRenewRebookConfig = false;
          state.error.getRenewRebookConfig = action.payload.error;
        },
      )
      .addCase(renewRebookActions.getCMConfig, (state) => {
        state.loading.getCMConfig = true;
        state.cmConfig = initialState.cmConfig;
      })
      .addCase(renewRebookActions.getCMConfigSuccess, (state, action) => {
        state.loading.getCMConfig = false;
        state.cmConfig = action.payload.response;
      })
      .addCase(renewRebookActions.getCMConfigFailure, (state, action) => {
        state.loading.getCMConfig = false;
        state.error.getCMConfig = action.payload.error;
      })
      .addCase(renewRebookActions.getPaymentDetails, (state) => {
        state.loading.getPaymentDetail = true;
        state.paymentDetail = initialState.paymentDetail;
      })
      .addCase(renewRebookActions.getPaymentDetailsSuccess, (state, action) => {
        state.loading.getPaymentDetail = false;
        const details = action.payload.response;
        state.paymentDetail = {
          ...details,
          serviceRequestLoanPaymentDetail:
          details.serviceRequestLoanPaymentDetailDtoList.map(
            (i: any) => ({
              ...i,
              userGoldLoanNucleusReceipt: i.userGoldLoanNucleusReceiptDtoList,
            }),
          ),
        };
      })
      .addCase(renewRebookActions.getPaymentDetailsFailure, (state, action) => {
        state.loading.getPaymentDetail = false;
        state.error.getPaymentDetail = action.payload.error;
      })
      .addCase(renewRebookActions.getLoanRenewalClosureDetails, (state) => {
        state.loading.getLoanRenewalClosureDetail = true;
        state.error.getLoanRenewalClosureDetail = null;
        state.loanRenewalClosureDetail = {
          GL: {
            ...state.loanRenewalClosureDetail.GL,
            igClosureAmount: null,
            igRenewalAmount: null,
            dummyReceiptAmount: null,
            receivedRenewalAmount: null,
          },
          PL: {
            ...state.loanRenewalClosureDetail.PL,
            igClosureAmount: null,
            igRenewalAmount: null,
            dummyReceiptAmount: null,
            receivedRenewalAmount: null,
          },
        };
      })
      .addCase(
        renewRebookActions.getLoanRenewalClosureDetailsSuccess,
        (state, action) => {
          const type = action.payload.type === "GOLD_LOAN" ? "GL" : "PL";
          state.loading.getLoanRenewalClosureDetail = false;
          state.error.getLoanRenewalClosureDetail = null;
          state.loanRenewalClosureDetail[type] = {
            ...state.loanRenewalClosureDetail[type],
            ...action.payload.response,
          };
        },
      )
      .addCase(
        renewRebookActions.getLoanRenewalClosureDetailsFailure,
        (state, action) => {
          state.loading.getLoanRenewalClosureDetail = false;
          state.error.getLoanRenewalClosureDetail = action.payload.error;
        },
      )
      .addCase(renewRebookActions.closeLoanRenewal, (state) => {
        state.loading.closeLoanRenewal = true;
        state.error.closeLoanRenewal = null;
      })
      .addCase(renewRebookActions.closeLoanRenewalSuccess, (state) => {
        state.loading.closeLoanRenewal = false;
        state.error.closeLoanRenewal = null;
      })
      .addCase(renewRebookActions.closeLoanRenewalFailure, (state, action) => {
        state.loading.closeLoanRenewal = false;
        state.error.closeLoanRenewal = action.payload.error;
      })
      .addCase(renewRebookActions.getLoanRenewalMappedPayments, (state) => {
        state.loading.getLoanRenewalMappedPayments = true;
        state.error.getLoanRenewalMappedPayments = null;
      })
      .addCase(
        renewRebookActions.getLoanRenewalMappedPaymentsSuccess,
        (state, action) => {
          state.loading.getLoanRenewalMappedPayments = false;
          state.error.getLoanRenewalMappedPayments = null;
          state.loanRenewalMappedPayments = {
            totalOutstandingPayment:
              action.payload.response.totalOutstandingPayment,
            totalPaymentAmount: action.payload.response.totalPaymentAmount,
            totalSubventionAmount:
              action.payload.response.totalSubventionAmount,
            serviceRequestMappedPaymentList:
              action.payload.response.serviceRequestMappedPaymentList,
            serviceRequestLoanPaymentDetailList:
              // eslint-disable-next-line max-len
              action.payload.response.serviceRequestLoanPaymentDetailDtoList.map(
                (i: any) => ({
                  outstandingPayment: i.outstandingPayment,
                  loanType: i.loanType,
                }),
              ),
          };
        },
      )
      .addCase(
        renewRebookActions.getLoanRenewalMappedPaymentsFailure,
        (state, action) => {
          state.loading.getLoanRenewalMappedPayments = false;
          state.error.getLoanRenewalMappedPayments = action.payload.error;
        },
      )
      .addCase(renewRebookActions.getLoanRenewalStatus, (state) => {
        state.loading.getLoanRenewalStatus = true;
        state.error.getLoanRenewalStatus = null;
      })
      .addCase(
        renewRebookActions.getLoanRenewalStatusSuccess,
        (state, action) => {
          state.loanRenewalClosureDetail[
            action.payload.type as "GL" | "PL"
          ].loanRenewalCloseStatus = action.payload.response.status;
          state.loanRenewalClosureDetail[
            action.payload.type as "GL" | "PL"
          ].isClosureAttempted = action.payload.response.isClosureAttempted;
          state.loading.getLoanRenewalStatus = false;
          state.error.getLoanRenewalStatus = null;
        },
      )
      .addCase(renewRebookActions.updateRoi, (state) => {
        state.loading.updateRoi = true;
      })
      .addCase(renewRebookActions.updateRoiSuccess, (state, action) => {
        state.loading.updateRoi = false;
        state.cmOfferDetails = {
          ...state.cmOfferDetails,
          ...action.payload.response,
        };
      })
      .addCase(renewRebookActions.updateRoiFailure, (state, action) => {
        state.loading.updateRoi = false;
        state.error.updateRoi = action.payload.error;
      })
      .addCase(renewRebookActions.getUserAcceptedOffer, (state) => {
        state.loading.getUserAcceptedOffer = true;
        state.userAcceptedOffer = null;
      })
      .addCase(
        renewRebookActions.getUserAcceptedOfferSuccess,
        (state, action) => {
          state.loading.getUserAcceptedOffer = false;
          state.userAcceptedOffer = action.payload.response;
        },
      )
      .addCase(renewRebookActions.getUserAcceptedOfferFailure, (state) => {
        state.loading.getUserAcceptedOffer = false;
        state.userAcceptedOffer = null;
      })
      .addCase(renewRebookActions.getGoldRate, (state) => {
        state.loading.getGoldRate = true;
      })
      .addCase(renewRebookActions.getGoldRateSuccess, (state, action) => {
        state.loading.getGoldRate = false;
        state.goldRate = action.payload.response;
      })
      .addCase(renewRebookActions.getGoldRateFailure, (state, action) => {
        state.loading.getGoldRate = false;
        state.error.getGoldRate = action.payload.error;
      })
      .addCase(renewRebookActions.getCMAdjustmentRenewalDetails, (state) => {
        state.loading.getCMAdjustmentRenewalDetail = true;
        state.error.getCMAdjustmentRenewalDetail = null;
      })
      .addCase(
        renewRebookActions.getCMAdjustmentRenewalDetailsSuccess,
        (state, action) => {
          state.CMAdjustmentRenewalDetail = action.payload;
          state.loading.getCMAdjustmentRenewalDetail = false;
          state.error.getCMAdjustmentRenewalDetail = null;
        },
      )
      .addCase(
        renewRebookActions.getCMAdjustmentRenewalDetailsFailure,
        (state, action) => {
          state.loading.getCMAdjustmentRenewalDetail = false;
          state.error.getCMAdjustmentRenewalDetail = action.payload.error;
        },
      )
      .addCase(renewRebookActions.updateCMAdjustmentRenewalDetails, (state) => {
        state.loading.updateCMAdjustmentRenewalDetail = true;
        state.error.updateCMAdjustmentRenewalDetail = null;
      })
      .addCase(
        renewRebookActions.updateCMAdjustmentRenewalDetailsSuccess,
        (state, action) => {
          state.CMAdjustmentRenewalDetail = action.payload;
          state.error.updateCMAdjustmentRenewalDetail = null;
          state.loading.updateCMAdjustmentRenewalDetail = false;
          state.openModals.cmAdjustmentRenewalDetail = false;
        },
      )
      .addCase(
        renewRebookActions.updateCMAdjustmentRenewalDetailsFailure,
        (state, action) => {
          state.error.updateCMAdjustmentRenewalDetail = action.payload.error;
          state.loading.updateCMAdjustmentRenewalDetail = false;
        },
      );
  },
});

const {
  selectAll: selectAllOffers,
} = offerListAdapter.getSelectors(
  (state: RootState) => state.serviceDesk.services.renewRebookState.offerList);

const {
  reloadList,
  updateActiveStep,
  setCompletedSteps,
  resetCompletedSteps,
  setLoanRenewalClosureDetail,
  setLoanRenewalCloseStatus,
  resetLoanRenewalClosureDetail,
  toggleModals,
  setForceSubmitCmForm,
  setActionLoading: setRenewRebookLoading,
  setActionError: setRenewRebookError,
  setActionSuccess: setRenewRebookSuccess,
  offerEngineApplicabilityReceived,
  offerListReceived,
  resetOfferEngine,
  setOfferDisplayedOnConsumerApp,
} = renewRebookSlice.actions;

export {initialState as renewRebookState};

export default renewRebookSlice.reducer;

export {
  initialState,
  reloadList,
  updateActiveStep,
  setCompletedSteps,
  resetCompletedSteps,
  setLoanRenewalClosureDetail,
  setLoanRenewalCloseStatus,
  resetLoanRenewalClosureDetail,
  toggleModals,
  setForceSubmitCmForm,
  setRenewRebookLoading,
  setRenewRebookError,
  setRenewRebookSuccess,
  offerEngineApplicabilityReceived,
  offerListReceived,
  resetOfferEngine,
  setOfferDisplayedOnConsumerApp,
  selectAllOffers,
};
