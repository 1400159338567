import {RootState} from "../store";

const collateralMovementSelectors = {
  getAllCollateralMovementList: (state: RootState) =>
    Object.values(state.collateralMovement.collateralMovementById),
  getFilters: (state: RootState) => state.collateralMovement.filters,
  getInwardReceiptImageByCrId: (state: RootState, crId: number) =>
    state.collateralMovement.inwardReceiptImage[crId],
  getSelectedCr: (state: RootState) => state.collateralMovement.selectedCrId,
  getOpenModals: (state: RootState) => state.collateralMovement.openModals,
  getPagination: (state: RootState) => state.collateralMovement.pagination,
  getLoading: (state: RootState) => state.collateralMovement.loading,
  getErrors: (state: RootState) => state.collateralMovement.errors,
  selectCollateralMovementById: (state: RootState, crId: number) =>
    state.collateralMovement.collateralMovementById[crId],
  selectDocumentsByCrId: (state: RootState, crId: number) =>
    state.collateralMovement.documents[crId],
  selectReloadList: (state: RootState) => state.collateralMovement.reloadList,
};

export default collateralMovementSelectors;
