import React, {useState, useCallback, useEffect} from "react";
import {Typography, Box} from "@mui/material";
import Container from "./styles/Container";
import SubContainer from "./styles/SubContainer";
import SubTitleCif from "./styles/SubTitleCif";
import MiniTitle from "./styles/MiniTitle";
import {useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import getCifDetails from "api/business/getCifDetails";
import getDocument from "api/business/getDocument";
import DocumentsWrapper from "../../LenderDocuments/styles/DocumentsWrapper";
import DocumentsImageWrapper from "../../LenderDocuments/styles/DocumentsImageWrapper";
import ZoomOutIconWrapper from "../../LenderDocuments/styles/ZoomOutIconWrapper";
import {ZoomOutMap} from "@mui/icons-material";
import DocumentImage from "../../LenderDocuments/styles/DocumentImage";
import DocView from "../../../CommonComponents/DocView";
import Loading from "app/components/Loading";
import CifContainer from "./styles/CifContainer";
import {IGButton} from "app/components";
import SkipCifConfirmationModal from "./Modals/SkipCifModal";
import {RolesEnum} from "app/enums/roles";

const CifValidation = ({crId, lenderName, hideSensitiveInfo}) => {
  const accessToken = useAppSelector(authSelectors.getAuthToken);
  const userInfo = useAppSelector(authSelectors.getUser);

  const [cifNumber, setCifNumber] = useState(null);
  const [cifReason, setCifReason] = useState(null);
  const [cifReferenceNo, setCifReferenceNo] = useState(null);
  const [cifStatus, setCifStatus] = useState(null);
  const [cifSuspensionStatus, setCifSuspensionStatus] = useState(null);
  const [isCifValidationDisable, setIsCifValidationDisable] = useState(false);
  const [cifDocDetails, setCifDocDetails] = useState(null);
  const [isCifDocViewerModal, setIsCifDocViewerModal] = useState(false);
  const [isPanLinkedWithCif, setIsPanLinkedWithCif] = useState(false);
  const [docCount, setDocCount] = useState(0);
  const [isSkipCifConfirmationModalOpen, setIsSkipCifConfirmationModalOpen] = useState(false);
  const [panLinkedWithCifError, setPanLinkedWithCifError] = useState(null);

  const fetchCifDetails = useCallback(async () => {
    const {error, statusCode, response} = await getCifDetails({
      accessToken,
      crId,
      lenderName,
    });
    if (statusCode === 200) {
      setCifNumber(response?.cif);
      setCifReason(response?.cifFailedReason);
      setCifStatus(response?.status);
      setCifReferenceNo(response?.lenderAadhaar);
      setCifSuspensionStatus(response?.lenderCustomerStatus);
      setIsPanLinkedWithCif(response?.isPanLinkedWithCif);
      setPanLinkedWithCifError(response?.panNotLinkedWithCifErrorMessage);
    }

    if (error) {
      setIsCifValidationDisable(true);
    } else {
    }
  }, [crId, accessToken]);

  useEffect(() => {
    fetchCifDetails();
  }, [fetchCifDetails]);

  const fetchDocumentDetails = useCallback(async () => {
    const {payload, statusCode} = await getDocument({
      accessToken,
      crId,
      documentType: ["CIF_SCREENSHOT"],
    });

    if (statusCode === 200) {
      if (payload.length) {
        setCifDocDetails(payload);
      }
    } else {
    }
  }, [accessToken, crId]);

  useEffect(() => {
    fetchDocumentDetails();
  }, [fetchDocumentDetails]);

  const docPrevHandler = () => {
    if (docCount > 0) {
      setDocCount(val => val - 1);
    }
  };

  const docNextHandler = () => {
    if (docCount < cifDocDetails.length - 1) {
      setDocCount(count => count + 1);
    }
  };

  const handleDocumentZoomClick = i => {
    setDocCount(i);
    setIsCifDocViewerModal(true);
  };

  const renderCifSuspension = () => {
    if (cifSuspensionStatus === "SUSPENDED") {
      return <Typography sx={{color: "red", fontWeight: "500"}}>{"SUSPENDED"}</Typography>;
    }
    if (cifSuspensionStatus === "ACTIVE") {
      return <Typography sx={{fontWeight: "500"}}>{"CIF Active"}</Typography>;
    }
  };
  return (
    <>
      {!isCifValidationDisable && (
        <CifContainer>
          <Container>
            <SubContainer>
              <SubTitleCif>CIF</SubTitleCif>
              <MiniTitle>{cifNumber ? <span>{cifNumber}</span> : "NA"}</MiniTitle>
            </SubContainer>
            <SubContainer>
              <SubTitleCif>Verification Status</SubTitleCif>
              {cifStatus === "COMPLETE" ? (
                <MiniTitle>
                  <span style={{color: "green"}}>{cifStatus} </span>
                </MiniTitle>
              ) : (
                <MiniTitle>
                  <span style={{color: "red"}}>{cifStatus} </span>
                </MiniTitle>
              )}
            </SubContainer>

            <SubContainer>
              <SubTitleCif>PAN Linked with CIF</SubTitleCif>

              <MiniTitle>
                <span style={{color: isPanLinkedWithCif ? "green" : "red"}}>
                  {isPanLinkedWithCif ? "Yes" : panLinkedWithCifError}
                </span>
              </MiniTitle>
            </SubContainer>

            <SubContainer>
              <SubTitleCif>Suspension Status</SubTitleCif>
              <MiniTitle>{renderCifSuspension()}</MiniTitle>
            </SubContainer>
            <SubContainer>
              <SubTitleCif>Reference No.</SubTitleCif>
              <MiniTitle>{cifReferenceNo ? <span>{cifReferenceNo}</span> : "NA"}</MiniTitle>
            </SubContainer>
            <SubContainer>
              <SubTitleCif>Reason</SubTitleCif>
              <MiniTitle>{cifReason ? <span>{cifReason}</span> : "NA"}</MiniTitle>
            </SubContainer>
          </Container>

          <Box ml={2} width="40%">
            {cifDocDetails ? (
              <DocumentsWrapper>
                {cifDocDetails?.map(
                  (document, i) =>
                    document.fileUrl && (
                      <div>
                        <span style={{margin: "1rem"}}>{document.documentType}</span>
                        <DocumentsImageWrapper>
                          <ZoomOutIconWrapper>
                            <ZoomOutMap
                              style={{fill: "#ce9f29"}}
                              onClick={() => handleDocumentZoomClick(i)}
                            />
                          </ZoomOutIconWrapper>
                          <DocumentImage
                            src={document.fileUrl}
                            onClick={() => handleDocumentZoomClick(i)}
                          />
                        </DocumentsImageWrapper>
                      </div>
                    ),
                )}
              </DocumentsWrapper>
            ) : (
              <Loading height="10vh" />
            )}

            {cifDocDetails && cifDocDetails.length > 0 && (
              <DocView
                open={isCifDocViewerModal}
                docsList={cifDocDetails.map(document => document.fileUrl).filter(doc => doc)}
                title={cifDocDetails[docCount]?.documentType}
                onCloseHandler={() => setIsCifDocViewerModal(false)}
                prevHandler={docPrevHandler}
                nextHandler={docNextHandler}
                docCount={docCount}
              />
            )}
            {userInfo?.userRoleList.includes(RolesEnum.LOAN_OPS_ADMIN) && !hideSensitiveInfo && (
              <Box textAlign="right">
                <IGButton
                  onClick={() => setIsSkipCifConfirmationModalOpen(true)}
                  color="golden"
                  fullWidth={false}
                >
                  Skip Cif Enable
                </IGButton>
              </Box>
            )}
          </Box>
        </CifContainer>
      )}
      {isSkipCifConfirmationModalOpen && (
        <SkipCifConfirmationModal
          crId={crId}
          lenderName={lenderName}
          isSkipCifConfirmationModalOpen={isSkipCifConfirmationModalOpen}
          setIsSkipCifConfirmationModalOpen={setIsSkipCifConfirmationModalOpen}
          isBtCifSkip={false}
        />
      )}
    </>
  );
};

export default CifValidation;
