import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GoldApiError} from "../../typings/api/goldApi.types";

import {
  GetCollateralMovementInterface,
  InwardReceiptImage,
} from "app/models/collateralMovement.model";
import collateralMovementActions from "./collateralMovement.actions";
import {
  getInitialState,
  PaginationState,
  paginationSliceCreator,
} from "../factoryReducers";
import {getValueFromQueryParams} from "_metronic";
import {getValidTime} from "_metronic/utils/moment";
import {CifDetailsInterface} from "app/models/loanRenewalRebook/cifDetails.model";

type LoadingErrorState = "getAllCollateralMovement" | "getInwardReceiptImage" | "approveJob";

export interface CollateralMovementFilters {
  currentSearchType: string;
  searchText: string;
  fromTime: number | null;
  toTime: number | null;
}
export interface CollateralMovementState {
  collateralMovementById: Record<number, GetCollateralMovementInterface>;
  inwardReceiptImage: Record<number, InwardReceiptImage>;
  documents: Record<number, CifDetailsInterface[]>;
  filters: CollateralMovementFilters;
  selectedCrId: number;
  openModals: {
    seeDetails: boolean;
    inwardReceiptModal: boolean;
  };
  pagination: PaginationState;
  loading: Record<LoadingErrorState, boolean>;
  errors: Record<LoadingErrorState, GoldApiError | null>;
  reloadList: boolean;
}

const initialState: CollateralMovementState = {
  collateralMovementById: {},
  inwardReceiptImage: {},
  documents: {},
  selectedCrId: 0,
  filters: {
    currentSearchType: getValueFromQueryParams("currentSearchType") || "",
    searchText: getValueFromQueryParams("searchText") || "",
    fromTime:
      Number(getValueFromQueryParams("fromTime")) ||
      getValidTime()
        .subtract("10", "days")
        .startOf("day")
        .valueOf(),
    toTime:
      Number(getValueFromQueryParams("endTime")) ||
      getValidTime()
        .endOf("day")
        .valueOf(),
  },
  openModals: {
    seeDetails: false,
    inwardReceiptModal: false,
  },
  loading: {
    getAllCollateralMovement: false,
    getInwardReceiptImage: false,
    approveJob: false,
  },
  errors: {
    getAllCollateralMovement: null,
    getInwardReceiptImage: null,
    approveJob: null,
  },
  pagination: getInitialState("collateralMovement"),
  reloadList: false,
};

const collateralMovementPaginationReducer = paginationSliceCreator(
  "collateralMovement",
);

const CollateralMovementSlice = createSlice({
  name: "collateralMovement",
  initialState,
  reducers: {
    toggleModals: (
      state,
      action: PayloadAction<{
        type: keyof typeof state.openModals;
        value: boolean;
      }>,
    ) => {
      const {type, value} = action.payload;
      state.openModals[type] = value;
    },
    resetCollateralMovementList: (state) => {
      state.collateralMovementById = {};
    },
    setFilters: (
      state,
      action: PayloadAction<Partial<CollateralMovementFilters>>,
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
      state.pagination.pageNo = 1;
    },
    resetFilters: (state) => {
      state.filters = {
        currentSearchType: "",
        searchText: "",
        fromTime: getValidTime()
          .subtract("10", "days")
          .startOf("day")
          .valueOf(),
        toTime: getValidTime()
          .endOf("day")
          .valueOf(),
      };
      state.pagination.pageNo = 1;
    },
    setSelectedCollateralCrId: (
      state,
      action: PayloadAction<{crId: number}>,
    ) => {
      state.selectedCrId = action.payload.crId;
    },
    setDocumentsByCrId: (
      state,
      action: PayloadAction<{crId: number; documents: CifDetailsInterface[]}>,
    ) => {
      state.documents[action.payload.crId] = action.payload.documents;
    },
    reloadList: (state) => {
      state.reloadList = !state.reloadList;
    },
    setLoading: (state, action: PayloadAction<{key: LoadingErrorState, value: boolean}>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(collateralMovementActions.getAllCollateralMovement, (state) => {
        state.loading.getAllCollateralMovement = true;
        state.errors.getAllCollateralMovement =
          initialState.errors.getAllCollateralMovement;
      })
      .addCase(
        collateralMovementActions.getAllCollateralMovementSuccess,
        (state, action) => {
          state.loading.getAllCollateralMovement = false;
          state.collateralMovementById = {};
          action.payload.response.forEach(
            (collateralMovement: GetCollateralMovementInterface) => {
              state.collateralMovementById[
                collateralMovement.crId
              ] = collateralMovement;
            },
          );
        },
      )
      .addCase(
        collateralMovementActions.getAllCollateralMovementFailure,
        (state, action) => {
          state.loading.getAllCollateralMovement = false;
          state.errors.getAllCollateralMovement = action.payload.error;
        },
      )
      .addCase(collateralMovementActions.getInwardReceiptImage, (state) => {
        state.loading.getInwardReceiptImage = true;
        state.errors.getInwardReceiptImage =
          initialState.errors.getInwardReceiptImage;
      })
      .addCase(
        collateralMovementActions.getInwardReceiptImageSuccess,
        (state, action) => {
          state.loading.getInwardReceiptImage = false;
          state.errors.getInwardReceiptImage =
            initialState.errors.getInwardReceiptImage;
          state.collateralMovementById[action.payload.crId] = {
            ...state.collateralMovementById[action.payload.crId],
            loanDetails: action.payload.response.loanDetails,
            lockerDetails: action.payload.response.lockerDetails,
            lmDistanceFromVault: action.payload.response.lmDistanceFromVault,
            documentTypeList: action.payload.response.documentTypeList,
          };
          state.inwardReceiptImage[action.payload.crId] = {
            inwardReceiptImageUrl: action.payload.response.lockerDetails.receiptImage,
          };
        },
      )
      .addCase(
        collateralMovementActions.getInwardReceiptImageFailure,
        (state, action) => {
          state.loading.getInwardReceiptImage = false;
          state.errors.getInwardReceiptImage = action.payload.error;
          state.openModals.seeDetails = false;
        },
      )
      .addMatcher(
        (action) => action.type.startsWith("collateralMovementPagination"),
        (state, action) => {
          state.pagination = collateralMovementPaginationReducer(
            state.pagination,
            action,
          );
        },
      ),
});

const {
  resetCollateralMovementList,
  setFilters,
  resetFilters,
  setSelectedCollateralCrId,
  toggleModals,
  setDocumentsByCrId,
  setLoading,
  reloadList,
} = CollateralMovementSlice.actions;

export {
  resetCollateralMovementList,
  setFilters,
  resetFilters,
  toggleModals,
  setSelectedCollateralCrId,
  setDocumentsByCrId,
  setLoading as setCollateralMovementLoading,
  reloadList as reloadCollateralMovementList,
};

export default CollateralMovementSlice.reducer;
