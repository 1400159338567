import {createAction} from "@reduxjs/toolkit";
import {
  GetCollateralMovementPayload,
  CollateralMovementResponse,
  GetInwardReceiptImagePayload,
} from "./collateralMovement.types";
import {LockerDetails, LoanDetails, DocumentType} from "app/models/collateralMovement.model";
import {setPaginationFor} from "../factoryReducers";
import {CrIdPayload} from "../lenderLoan/lenderLoan.types";

const collateralMovementActionTypes = {
  GetAllCollateralMovement: "[collateralMovement] Get All Collateral Movement",
  GetAllCollateralMovementSuccess:
    "[collateralMovement] Get All  Collateral Movement Success",
  GetAllCollateralMovementFailure:
    "[collateralMovement] Get All Collateral Movement Failure",
  GetInwardReceiptImage: "[collateralMovement] Get Inward Receipt Image",
  GetInwardReceiptImageSuccess:
    "[collateralMovement] Get Inward Receipt Image Success",
  GetInwardReceiptImageFailure:
    "[collateralMovement] Get Inward Receipt Image Failure",
  ApproveJob: "[collateralMovement] Approve Job",
};

const collateralMovementActions = {
  getAllCollateralMovement: createAction<GetCollateralMovementPayload>(
    collateralMovementActionTypes.GetAllCollateralMovement,
  ),
  getAllCollateralMovementSuccess: createAction<CollateralMovementResponse>(
    collateralMovementActionTypes.GetAllCollateralMovementSuccess,
  ),
  getAllCollateralMovementFailure: createAction<CollateralMovementResponse>(
    collateralMovementActionTypes.GetAllCollateralMovementFailure,
  ),
  getInwardReceiptImage: createAction<GetInwardReceiptImagePayload>(
    collateralMovementActionTypes.GetInwardReceiptImage,
  ),
  getInwardReceiptImageSuccess: createAction<{
    response: {
      lmDistanceFromVault: string;
      lockerDetails: LockerDetails;
      loanDetails: LoanDetails;
      documentTypeList: DocumentType[];
    };
    crId: number;
  }>(collateralMovementActionTypes.GetInwardReceiptImageSuccess),
  approveJob: createAction<CrIdPayload>(collateralMovementActionTypes.ApproveJob),
  getInwardReceiptImageFailure: createAction<CollateralMovementResponse>(
    collateralMovementActionTypes.GetInwardReceiptImageFailure,
  ),
  setCollateralMovementPagination: setPaginationFor("collateralMovement"),
};

export default collateralMovementActions;

export {collateralMovementActionTypes};
