import React, {useCallback, useEffect} from "react";
import {useAppSelector} from "app/store/hooks";
import {unscheduledSelectors, unscheduledActions} from "app/store/scheduling/unscheduled";
import {Grid, Paper, Typography, Box, useTheme} from "@mui/material";
import {creditReportStatusEnum} from "app/enums/gold_loan/recommendation";
import {useDispatch} from "react-redux";
import {IGButton, IGLoading} from "app/components";
import {leadActions, leadSelectors} from "app/store/lead";

const Container: React.FC = ({children}) => {
  const theme = useTheme();
  return (
    <Box
      bgcolor="#FFFFFF"
      boxShadow={"2px 2px 2px 2px #E0E0E0"}
      border={`1px solid ${theme.palette.grey.A400}`}
      borderRadius={1}
      p={2}
      minWidth="200px"
    >
      {children}
    </Box>
  );
};

const CreditReportDetail = ({detail}: { detail: CreditReportDetail }) => (
  <Grid container>
    <Grid item xs={6} spacing={3} p={1}>
      <Typography align="left">{detail.name}</Typography>
    </Grid>
    <Grid item xs={6} spacing={3} p={1}>
      <Typography align="right" fontWeight={500}>
        {detail.value}
      </Typography>
    </Grid>
  </Grid>
);

const NoCreditHistory = () => (
  <Paper>
    <Typography align="center">No Credit History found!</Typography>
  </Paper>
);

const CreditReport = ({
  creditReportDetails,
  link,
}: {
  creditReportDetails: Array<CreditReportDetail>;
  link?: string;
}) => {
  const viewCreditReport = () => {
    if (link) {
      const win = window.open(
        "",
        "_blank",
        "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=500",
      );
      if (win) {
        win.document.body.innerHTML = link || "";
      }
    }
  };
  return (
    <>
      {creditReportDetails?.map(detail => (
        <CreditReportDetail key={detail.name} detail={detail} />
      ))}
      <Box m={1} pb={2}>
        {link && (
          <IGButton color="golden" onClick={viewCreditReport}>
            View Credit Report
          </IGButton>
        )}
      </Box>
    </>
  );
};

const LeadCreditHistory: React.FC<LeadCreditHistoryProps> = ({isLsqFlow = false, leadId}) => {
  const dispatch = useDispatch();
  //Selectors
  const {
    isFetching: isLeadCreditReportStatusFetching,
    data: leadCreditReportStatus,
  } = useAppSelector(unscheduledSelectors.getUnscheduledLeadCreditReportStatus);
  const {isFetching: isLeadCreditDetailsFetching, data: leadCreditDetails} = useAppSelector(
    unscheduledSelectors.getUnscheduledLeadCreditDetails,
  );
  const parentLeadIdCreditDetails = useAppSelector(state =>
    leadSelectors.getCreditDetails(state, leadId),
  );
  const {getParentLeadIdCreditDetail: isParentLeadIdCreditDetailLoading} = useAppSelector(
    leadSelectors.getLoading,
  );
  const {getParentLeadId: isParentLeadIdLoading} = useAppSelector(leadSelectors.getLoading);
  //Variables
  const isCreditReportStatusSuccess =
    leadCreditReportStatus?.status === creditReportStatusEnum.SUCCESS;
  const loadingToUse = isLsqFlow
    ? isParentLeadIdCreditDetailLoading || isParentLeadIdLoading
    : isLeadCreditDetailsFetching || isLeadCreditReportStatusFetching;
  const creditDetailsToUse = isLsqFlow ? parentLeadIdCreditDetails : leadCreditDetails;
  const {creditScore, activeAccounts, totalAccounts, wilfulDefault, link} =
    creditDetailsToUse || {};
  const showNoCreditHistory =
    (!isLsqFlow && !isCreditReportStatusSuccess && !isLeadCreditReportStatusFetching) ||
    !creditScore ||
    !leadId;
  const creditReportDetails: Array<CreditReportDetail> = [
    {
      name: "Credit Score",
      value: creditScore ?? "NA",
    },

    {
      name: "Active Accounts",
      value: activeAccounts ?? "NA",
    },
    {
      name: "Total Accounts",
      value: totalAccounts ?? "NA",
    },

    {
      name: "WillFul Default Status",
      value: (
        <Typography
          sx={{
            color: wilfulDefault ? "red" : "green",
          }}
        >
          {wilfulDefault === null ? "NA" : String(wilfulDefault)}
        </Typography>
      ),
    },
  ];

  //Handlers
  const fetchCreditDetails = useCallback(() => {
    if (!isLsqFlow) {
      dispatch(
        unscheduledActions.getLeadCreditReportStatus({
          id: leadId,
        }),
      );
    } else {
      dispatch(
        leadActions.getParentLeadIdCreditDetail({
          parentLeadId: leadId,
        }),
      );
    }
  }, [dispatch, leadId, isLsqFlow]);

  const handleCreditReportStatus = useCallback(() => {
    if (isCreditReportStatusSuccess && !isLsqFlow) {
      dispatch(
        unscheduledActions.getLeadCreditDetails({
          id: leadId,
        }),
      );
    }
  }, [dispatch, leadId, isLsqFlow, isCreditReportStatusSuccess]);

  //Effects
  useEffect(() => {
    fetchCreditDetails();
  }, [fetchCreditDetails]);

  useEffect(() => {
    handleCreditReportStatus();
  }, [handleCreditReportStatus]);

  if (showNoCreditHistory) {
    return <NoCreditHistory />;
  }

  if (loadingToUse) {
    return (
      <Container>
        <IGLoading height="10vh" />
      </Container>
    );
  }

  return (
    <Container>
      <Paper>
        <CreditReport creditReportDetails={creditReportDetails} link={link} />
      </Paper>
    </Container>
  );
};

interface LeadCreditHistoryProps {
  isLsqFlow: boolean;
  leadId: number;
}

interface CreditReportDetail {
  name: string;
  value: string | number | JSX.Element;
}

export default LeadCreditHistory;
